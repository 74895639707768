export default defineI18nConfig(() => ({
    legacy: false,
    datetimeFormats: {
        nl: {
            dateShort: {
                month: 'short', day: 'numeric', year: 'numeric'
            },
            time: {
                hour: 'numeric', minute: 'numeric'
            },
            dateLong: {
                month: 'long', day: 'numeric', year: 'numeric'
            }
        },
        en: {
            dateShort: {
                month: 'short', day: 'numeric', year: 'numeric'
            },
            time: {
                hour: 'numeric', minute: 'numeric'
            },
            dateLong: {
                month: 'long', day: 'numeric', year: 'numeric'
            }
        }
    },
    locale: 'nl',
    globalInjection: true,
    inheritLocale: true,
    fallbackLocale: 'nl',
    missingWarn: false,
    fallbackWarn: false
}));
